/** Based on tableFilter, reworked to simplify options and add translation support. Depends on EntityList functionality. */
import * as angular from 'angular';
import { EntityList } from '@common/EntityList';

angular.module('app.core').filter('entityList', ($filter) => {
  return (entityList: EntityList<any>) => {
    let items = entityList?.entities;

    if (!items?.length) {
      return [];
    }

    // Filtering by multiple fields.
    items = $filter('filter')(items, (value, index, array) => {
      return entityList.filterData(value, index, array);
    });

    entityList.total = items.length;

    if (entityList.pageSize == 0) {
      entityList.pageSize = items.length;
    }

    items = $filter('orderBy')(items, entityList.orderByField, entityList.reverseSort);
    items = items.slice(entityList.calculateStartFrom());

    entityList.setTypeCounters();

    return $filter('limitTo')(items, entityList.pageSize);
  };
});
import * as angular from 'angular';

angular.module('app.core').filter('tableFilter', ($filter) => {
  const addPropertyChain = (chain, val, obj) => {
    const propChain = chain.split('.');

    if (propChain.length === 1) {
      obj[propChain[0]] = val;

      return;
    }

    const first = propChain.shift();

    if (!obj[first]) {
      obj[first] = {};
    }

    addPropertyChain(propChain.join('.'), val, obj[first]);
  };

  return (items, tableControl) => {
    if (!items) {
      return [];
    }

    if (tableControl.searchField) {
      const searchExpr = {};

      addPropertyChain(tableControl.searchField, tableControl.searchKeyword, searchExpr);

      items = $filter('filter')(items, searchExpr);
    } else if (tableControl.searchFields?.length && tableControl.identifierField) {
      let tempItems = [];

      for (let idx = 0; idx < tableControl.searchFields.length; idx++) {
        const searchExpr = {};

        addPropertyChain(tableControl.searchFields[idx], tableControl.searchKeyword, searchExpr);

        tempItems = tempItems.concat($filter('filter')(items, searchExpr));
      }
    }

    tableControl.total = items.length;

    if (tableControl.pageSize == 0) {
      tableControl.pageSize = items.length;
    }

    items = $filter('orderBy')(items, tableControl.orderByField, tableControl.reverseSort);
    items = items.slice(tableControl.calculateStartFrom());

    return $filter('limitTo')(items, tableControl.pageSize);
  };
});
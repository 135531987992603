import * as angular from 'angular';

class MsWidgetController {
  flipped: boolean;
  $scope: any;
  $element: any;

  constructor($scope, $element) {
    this.$scope = $scope;
    this.$element = $element;
    this.flipped = false;
  }

  flip() {
    if (!this.isFlippable()) {
      return;
    }

    this.flipped = !this.flipped;
    this.$element.toggleClass('flipped', this.flipped);
  };

  isFlippable() {
    return angular.isDefined(this.$scope.flippable) && this.$scope.flippable === true;
  };
}

const msWidgetDirective = () => {
  return {
    restrict: 'E',
    scope: {
      flippable: '=?',
    },
    controller: MsWidgetController,
    transclude: true,
    compile: (tElement) => {
      tElement.addClass('ms-widget');

      return (scope, iElement, iAttrs, MsWidgetCtrl, transcludeFn) => {
        // Custom transclusion
        transcludeFn((clone) => {
          iElement.empty();
          iElement.append(clone);
        });
      };
    },
  };
}

const msWidgetFrontDirective = () => {
  return {
    restrict: 'E',
    require: '^msWidget',
    transclude: true,
    compile: (tElement) => {
      tElement.addClass('ms-widget-front');

      return (scope, iElement, iAttrs, MsWidgetCtrl, transcludeFn) => {
        // Custom transclusion
        transcludeFn((clone) => {
          iElement.empty();
          iElement.append(clone);
        });

        // Methods
        scope.flipWidget = MsWidgetCtrl.flip;
      };
    },
  };
}

const msWidgetBackDirective = () => {
  return {
    restrict: 'E',
    require: '^msWidget',
    transclude: true,
    compile: (tElement) => {
      tElement.addClass('ms-widget-back');

      return (scope, iElement, iAttrs, MsWidgetCtrl, transcludeFn) => {
        // Custom transclusion
        transcludeFn((clone) => {
          iElement.empty();
          iElement.append(clone);
        });

        // Methods
        scope.flipWidget = MsWidgetCtrl.flip;
      };
    },
  };
}

angular
  .module('app.core')
  .controller('MsWidgetController', MsWidgetController)
  .directive('msWidget', msWidgetDirective)
  .directive('msWidgetFront', msWidgetFrontDirective)
  .directive('msWidgetBack', msWidgetBackDirective);

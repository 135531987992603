import * as angular from 'angular';

angular.module('app.core').filter('pastDaysByDayNumber', () => {
  return (items: fuse.planIrrigationInfo[], todayDayNumber: number) => {
    const output = [];
    angular.forEach(items, (item) => {
      if (item.dayNumber < todayDayNumber) output.push(item);
    });
    return output;
  };
});

import * as angular from 'angular';

angular.module('app.core').filter('todayAndFutureByDayNumber', () => {
  return (items: fuse.planIrrigationInfo[], localeTodayDayNumber: number) => {
    const output = [];

    angular.forEach(items, (item) => {
      if (item.dayNumber >= localeTodayDayNumber) {
        output.push(item);
      }
    });

    return output;
  };
});

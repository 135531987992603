// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*----------------------------------------------------------------*/
/*  ms-responsive-table
/*----------------------------------------------------------------*/
.ms-responsive-table-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
}`, "",{"version":3,"sources":["webpack://./src/app/core/directives/ms-responsive-table/ms-responsive-table.scss"],"names":[],"mappings":"AAAA,mEAAA;AACA;mEAAA;AAGA;EACI,gBAAA;EACA,kBAAA;AAAJ","sourcesContent":["/*----------------------------------------------------------------*/\r\n/*  ms-responsive-table\r\n/*----------------------------------------------------------------*/\r\n\r\n.ms-responsive-table-wrapper {\r\n    overflow-x: auto;\r\n    overflow-y: hidden;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

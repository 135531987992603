import * as angular from 'angular';

angular.module('app.core').filter('customer', ($filter) => {
  return (items, tableControl) => {
    if (!items) {
      return [];
    }

    items = items.filter((contact) => {
      const fullName: string = (contact.FirstName + ' ' + contact.LastName).toLowerCase();
      const incl: boolean = angular.isDefined(tableControl.searchKeyword)
        ? (contact.Email && contact.Email.toLowerCase().indexOf(tableControl.searchKeyword.toLowerCase()) > -1) ||
          fullName.indexOf(tableControl.searchKeyword.toLowerCase()) > -1
        : true;
      return incl;
    });

    tableControl.total = items.length;
    items = $filter('orderBy')(items, tableControl.orderByField, tableControl.reverseSort);
    items = items.slice(tableControl.calculateStartFrom());

    return $filter('limitTo')(items, tableControl.pageSize);
  }
});
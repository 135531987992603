import * as angular from 'angular';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';

class MsNavigationServiceProvider {
  navigation: any[];
  $log: any;

  constructor() {
    // Inject $log service
    this.$log = angular.injector(['ng']).get('$log');

    // Navigation array
    this.navigation = [];
  }

  saveItem(path: string, item: any) {
    if (!angular.isString(path)) {
      this.$log.error('path must be a string (eg. `account.dashboard`)');
      return;
    }

    const parts = path.split('.');

    // Generate the object id from the parts
    const id = parts[parts.length - 1];

    // Get the parent item from the parts
    const parent = this._findOrCreateParent(parts);

    // Decide if we are going to update or create
    let updateItem = false;

    for (let i = 0; i < parent.length; i++) {
      if (parent[i]._id === id) {
        updateItem = parent[i];
        break;
      }
    }

    // Update
    if (updateItem) {
      angular.extend(updateItem, item);
    }
    // Create
    else {
      item.children = [];

      // Add the default weight if not provided or if it's not a number
      if (angular.isUndefined(item.weight) || !angular.isNumber(item.weight)) {
        item.weight = 1;
      }

      item._id = id;
      item._path = path;
      item.uisref = this._getUiSref(item);

      parent.push(item);
    }
  }

  deleteItem(path: string) {
    if (!angular.isString(path)) {
      this.$log.error('path must be a string (eg. `dashboard.project`)');
      return;
    }

    // Locate the item by using given path
    let item = this.navigation;
    const parts = path.split('.');

    for (let p = 0; p < parts.length; p++) {
      const id = parts[p];

      for (let i = 0; i < item.length; i++) {
        if (item[i]._id === id) {
          // If we have a matching path,
          // we have found our object, remove it.
          if (item[i]._path === path) {
            item.splice(i, 1);
            return true;
          }

          // Otherwise grab the children of
          // the current item and continue.
          item = item[i].children;
          break;
        }
      }
    }
    return false;
  }

  /**
   * Sort the navigation items by their weights
   */
  sortByWeight(parent: any = this.navigation) {
    // If parent not provided, sort the root items
    if (!parent) {
      parent = this.navigation;
      parent = ArrayUtils.sortByNumber(parent, (x: any) => parseInt(x.weight));
    }

    for (let i = 0; i < parent.length; i++) {
      let children = parent[i].children;

      if (children.length > 1) {
        children = ArrayUtils.sortByNumber(children, (x: any) => parseInt(x.weight));
      }

      if (children.length) {
        this.sortByWeight(children);
      }
    }
  }

  /**
   * Find or create parent
   */
  _findOrCreateParent(parts: string[]) {
    // Store the main navigation.
    let parent = this.navigation;

    // If it's going to be a root item
    // return the navigation itself.
    if (parts.length === 1) {
      return parent;
    }

    // Remove the last element from the parts as
    // we don't need that to figure out the parent.
    parts.pop();

    // Find and return the parent
    for (let i = 0; i < parts.length; i++) {
      const _id = parts[i];
      let createParent = true;

      for (let p = 0; p < parent.length; p++) {
        if (parent[p]._id === _id) {
          parent = parent[p].children;
          createParent = false;
          break;
        }
      }

      // If there is no parent found, create one, push
      // it into the current parent and assign it as a
      // new parent
      if (createParent) {
        const item = {
          _id: _id,
          _path: parts.join('.'),
          title: _id,
          weight: 1,
          children: [],
        };

        parent.push(item);
        parent = item.children;
      }
    }

    return parent;
  }

  /**
   * Setup the ui-sref using state & state parameters
   */
  _getUiSref(item: any) {
    let uisref = '';

    if (angular.isDefined(item.state)) {
      uisref = item.state;

      if (angular.isDefined(item.stateParams) && angular.isObject(item.stateParams)) {
        uisref = `${uisref}(${angular.toJson(item.stateParams)})`;
      }
    }
    return uisref;
  }

  $get() {
    let activeItem = null;
    let navigationScope = null;
    let folded = null;
    let foldedOpen = null;

    const getNav = (root) => {
      if (root) {
        for (let i = 0; i < this.navigation.length; i++) {
          if (this.navigation[i]._id === root) {
            return [this.navigation[i]];
          }
        }

        return null;
      }

      return this.navigation;
    };

    return {
      saveItem: this.saveItem.bind(this),
      deleteItem: this.deleteItem.bind(this),
      sort: this.sortByWeight.bind(this),
      /**
       * Clear the entire navigation
       */
      clearNavigation: () => {
        this.navigation = [];
        if (navigationScope) {
          navigationScope.vm.navigation = this.navigation;
        }
      },
      setActiveItem: (node, scope) => {
        activeItem = { node, scope };
      },
      getActiveItem: () => activeItem,
      /**
       * Return navigation array
       */
      getNavigation: getNav,
      /**
       * Return flat navigation array
       */
      getFlatNavigation: (root) => this._flattenNavigation(getNav(root)),
      /**
       * Store navigation's scope for later use
       */
      setNavigationScope: (scope) => {
        navigationScope = scope;
      },
      /**
       * Set folded status
       */
      setFolded: (status) => {
        folded = status;
      },
      /**
       * Return folded status
       */
      getFolded: () => folded,
      /**
       * Set folded open status
       */
      setFoldedOpen: (status) => {
        foldedOpen = status;
      },
      /**
       * Return folded open status
       */
      getFoldedOpen: () => foldedOpen,
      /**
       * Toggle fold on stored navigation's scope
       */
      toggleFolded: () => {
        navigationScope.toggleFolded();
      },
    };
  }

   /**
    * Flatten the given navigation
    */
  _flattenNavigation(navigation) {
    let flatNav = [];

    for (let x = 0; x < navigation.length; x++) {
      // Copy and clear the children of the
      // navigation that we want to push.
      const navToPush = angular.copy(navigation[x]);

      navToPush.children = [];

      flatNav.push(navToPush);

      // If there are child items in this navigation, process it.
      if (navigation[x].children.length) {
        flatNav = flatNav.concat(this._flattenNavigation(navigation[x].children));
      }
    }

    return flatNav;
  }
}

class MsNavigationController {
  navigation: any[];
  msNavigationService: any;

  constructor(private $scope: any, msNavigationService: any) {
    this.msNavigationService = msNavigationService;

    if (this.$scope.root) {
      this.navigation = msNavigationService.getNavigation(this.$scope.root);
    } else {
      this.navigation = msNavigationService.getNavigation();
    }

    this.init();
  }

  init() {
    // Sort the navigation before doing anything else.
    this.msNavigationService.sort();
  }

  toggleHorizontalMobileMenu() {
    angular
      .element(document.querySelector('body'))
      .toggleClass('ms-navigation-horizontal-mobile-menu-active');
  }
}

const msNavigationDirective = ($rootScope, $timeout, $mdSidenav, msNavigationService) => {
  return {
    restrict: 'E',
    scope: {
      folded: '=',
      root: '@',
    },
    controller: MsNavigationController,
    controllerAs: 'vm',
    templateUrl: 'src/app/core/directives/ms-navigation/templates/vertical.html',
    transclude: true,
    compile: (tElement) => {
      tElement.addClass('ms-navigation');

      return (scope, iElement) => {
        const bodyEl = angular.element(document.querySelector('body')),
          foldExpanderEl = angular.element('<div id="ms-navigation-fold-expander"></div>'),
          foldCollapserEl = angular.element('<div id="ms-navigation-fold-collapser"></div>'),
          sidenav = $mdSidenav('navigation');

        // Store the navigation in the service for public access
        msNavigationService.setNavigationScope(scope);

        const init = () => {
          // Set the folded status for the first time.
          // First, we have to check if we have a folded
          // status available in the service already. This
          // will prevent navigation to act weird if we already
          // set the fold status, remove the navigation and
          // then re-initialize it, which happens if we
          // change to a view without a navigation and then
          // come back with history.back() function.

          // If the service didn't initialize before, set
          // the folded status from scope, otherwise we
          // won't touch anything because the folded status
          // already set in the service...
          if (msNavigationService.getFolded() === null) {
            msNavigationService.setFolded(scope.folded);
          }

          if (msNavigationService.getFolded()) {
            // Collapse everything.
            // This must be inside a $timeout because by the
            // time we call this, the 'msNavigation::collapse'
            // event listener is not registered yet. $timeout
            // will ensure that it will be called after it is
            // registered.
            $timeout(() => {
              $rootScope.$broadcast('msNavigation::collapse');
            });

            // Add class to the body
            bodyEl.addClass('ms-navigation-folded');

            // Set fold expander
            setFoldExpander();
          }
        }

        // Sidenav locked open status watcher
        scope.$watch(
          () => {
            return sidenav.isLockedOpen();
          },
          (current, old) => {
            if (angular.isUndefined(current) || angular.equals(current, old)) {
              return;
            }

            const folded = msNavigationService.getFolded();

            if (folded) {
              if (current) {
                // Collapse everything
                $rootScope.$broadcast('msNavigation::collapse');
              } else {
                // Expand the active one and its parents
                const activeItem = msNavigationService.getActiveItem();
                if (activeItem) {
                  activeItem.scope.$emit('msNavigation::stateMatched');
                }
              }
            }
          },
        );

        // Folded status watcher
        scope.$watch('folded', (current, old) => {
          if (angular.isUndefined(current) || angular.equals(current, old)) {
            return;
          }

          setFolded(current);
        });

        /**
         * Set folded status
         */
        const setFolded = (folded) => {
          // Store folded status on the service for global access
          msNavigationService.setFolded(folded);

          if (folded) {
            // Collapse everything
            $rootScope.$broadcast('msNavigation::collapse');

            // Add class to the body
            bodyEl.addClass('ms-navigation-folded');

            // Set fold expander
            setFoldExpander();
          } else {
            // Expand the active one and its parents
            const activeItem = msNavigationService.getActiveItem();
            if (activeItem) {
              activeItem.scope.$emit('msNavigation::stateMatched');
            }

            // Remove body class
            bodyEl.removeClass('ms-navigation-folded ms-navigation-folded-open');

            // Remove fold collapser
            removeFoldCollapser();
          }
        }

        /**
         * Set fold expander
         */
        const setFoldExpander = () => {
          iElement.parent().append(foldExpanderEl);

          // Let everything settle for a moment
          // before registering the event listener
          $timeout(() => {
            foldExpanderEl.on('mouseenter touchstart', onFoldExpanderHover);
          });
        }

        /**
         * Set fold collapser
         */
        const setFoldCollapser = () => {
          bodyEl.find('#main').append(foldCollapserEl);
          foldCollapserEl.on('mouseenter touchstart', onFoldCollapserHover);
        }

        /**
         * Remove fold collapser
         */
        const removeFoldCollapser = () => {
          foldCollapserEl.remove();
        }

        /**
         * onHover event of foldExpander
         */
        const onFoldExpanderHover = (event) => {
          if (event) {
            event.preventDefault();
          }

          // Set folded open status
          msNavigationService.setFoldedOpen(true);

          // Expand the active one and its parents
          const activeItem = msNavigationService.getActiveItem();
          if (activeItem) {
            activeItem.scope.$emit('msNavigation::stateMatched');
          }

          // Add class to the body
          bodyEl.addClass('ms-navigation-folded-open');

          // Remove the fold opener
          foldExpanderEl.remove();

          // Set fold collapser
          setFoldCollapser();
        }

        /**
         * onHover event of foldCollapser
         */
        const onFoldCollapserHover = (event) => {
          if (event) {
            event.preventDefault();
          }

          // Set folded open status
          msNavigationService.setFoldedOpen(false);

          // Collapse everything
          $rootScope.$broadcast('msNavigation::collapse');

          // Remove body class
          bodyEl.removeClass('ms-navigation-folded-open');

          // Remove the fold collapser
          foldCollapserEl.remove();

          // Set fold expander
          setFoldExpander();
        }

        /**
         * Public access for toggling folded status externally
         */
        scope.toggleFolded = () => {
          const folded = msNavigationService.getFolded();

          setFolded(!folded);
        };

        /**
         * On $stateChangeStart
         */
        scope.$on('$stateChangeStart', () => {
          // Close the sidenav
          sidenav.close();
        });

        // Cleanup
        scope.$on('$destroy', () => {
          foldCollapserEl.off('mouseenter touchstart');
          foldExpanderEl.off('mouseenter touchstart');
        });

        init();
      };
    },
  };
}

class MsNavigationNodeController {
  $scope: any;
  $element: any;
  $rootScope: any;
  $animate: any;
  $state: any;
  $transitions: any;
  msNavigationService: any;

  group: boolean;
  node: any;
  hasChildren: boolean;
  collapsed: boolean;
  collapsable: boolean;
  animateHeightClass: string;

  constructor($scope, $element, $rootScope, $animate, $state, $transitions, msNavigationService) {
    this.$scope = $scope;
    this.$element = $element;
    this.$rootScope = $rootScope;
    this.$animate = $animate;
    this.$state = $state;
    this.$transitions = $transitions;
    this.msNavigationService = msNavigationService;

    this.group = false;
    this.node = $scope.node;
    this.hasChildren = false;
    this.collapsed = false;
    this.collapsable = false;
    this.animateHeightClass = 'animate-height';

    this.init();

    this.toggleCollapsed = this.toggleCollapsed.bind(this);
  }

  /**
   * Check if node should be hidden.
   */
  get isHidden(): boolean {
    if (angular.isDefined(this.node.hidden) && angular.isFunction(this.node.hidden)) {
      return this.node.hidden();
    }

    return false;
  }

  init() {
    // Has children?
    this.hasChildren = !!this.node.children.length;

    // Is group?
    this.group = !!(angular.isDefined(this.node.group) && this.node.group === true);

    // Is collapsable?
    if (!this.hasChildren || this.group) {
      this.collapsable = false;
    } else {
      this.collapsable = !!(
        angular.isUndefined(this.node.collapsable) ||
        typeof this.node.collapsable !== 'boolean' ||
        this.node.collapsable === true
      );
    }

    // Is collapsed?
    if (!this.collapsable) {
      this.collapsed = false;
    } else {
      this.collapsed = !!(
        angular.isUndefined(this.node.collapsed) ||
        typeof this.node.collapsed !== 'boolean' ||
        this.node.collapsed === true
      );
    }

    // Expand all parents if we have a matching state or
    // the current state is a child of the node's state.
    if (this.node.state === this.$state.current.name || this.$state.includes(this.node.state)) {
      // If state params are defined, make sure they are
      // equal, otherwise do not set the active item.
      if (
        angular.isDefined(this.node.stateParams) &&
        angular.isDefined(this.$state.params) &&
        !angular.equals(this.node.stateParams, this.$state.params)
      ) {
        return;
      }

      this.$scope.$emit('msNavigation::stateMatched');

      // Also store the current active menu item.
      this.msNavigationService.setActiveItem(this.node, this.$scope);
    }

    this.$scope.$on('msNavigation::stateMatched', () => {
      // Expand if the current scope is collapsable and is collapsed.
      if (this.collapsable && this.collapsed) {
        this.$scope.$evalAsync(() => {
          this.collapsed = false;
        });
      }
    });

    // Listen for collapse event.
    this.$scope.$on('msNavigation::collapse', (event, path) => {
      if (this.collapsed || !this.collapsable) {
        return;
      }

      // If there is no path defined, collapse.
      if (angular.isUndefined(path)) {
        this.collapse();
      }
      // If there is a path defined, do not collapse
      // the items that are inside that path.
      // This will prevent parent items from collapsing.
      else {
        const givenPathParts = path.split('.');
        let activePathParts = [];

        const activeItem = this.msNavigationService.getActiveItem();
        if (activeItem) {
          activePathParts = activeItem.node._path.split('.');
        }

        if (givenPathParts.indexOf(this.node._id) > -1 || activePathParts.indexOf(this.node._id) > -1) {
          return;
        }

        this.collapse();
      }
    });

    // Listen for transitions 'onSuccess' event.
    this.$transitions.onSuccess({}, () => {
      if (this.node.state === this.$state.current.name) {
        // If state params are defined, make sure they are
        // equal, otherwise do not set the active item.
        if (
          angular.isDefined(this.node.stateParams) &&
          angular.isDefined(this.$state.params) &&
          !angular.equals(this.node.stateParams, this.$state.params)
        ) {
          return;
        }

        // Update active item on state change.
        this.msNavigationService.setActiveItem(this.node, this.$scope);

        // Collapse everything except the one we're using.
        this.$rootScope.$broadcast('msNavigation::collapse', this.node._path);
      }

      // Expand the parents if we the current
      // state is a child of the node's state.
      if (this.$state.includes(this.node.state)) {
        // If state params are defined, make sure they are
        // equal, otherwise do not set the active item.
        if (
          angular.isDefined(this.node.stateParams) &&
          angular.isDefined(this.$state.params) &&
          !angular.equals(this.node.stateParams, this.$state.params)
        ) {
          return;
        }

        // Emit the stateMatched.
        this.$scope.$emit('msNavigation::stateMatched');
      }
    });
  }

  toggleCollapsed() {
    if (this.collapsed) {
      this.expand();
    } else {
      this.collapse();
    }
  }

  collapse() {
    // Grab the element that we are going to collapse.
    const collapseEl = this.$element.children('ul');
    // Grab the height.
    const height = collapseEl[0].offsetHeight;

    this.$scope.$evalAsync(() => {
      // Set collapsed status.
      this.collapsed = true;

      // Add collapsing class to the node.
      this.$element.addClass('collapsing');

      // Animate the height.
      this.$animate
        .animate(
          collapseEl,
          { display: 'block', height: height + 'px' },
          { height: '0px' },
          this.animateHeightClass,
        )
        .then(() => {
          // Clear the inline styles after animation done.
          collapseEl.css({ display: '', height: '' });

          // Clear collapsing class from the node.
          this.$element.removeClass('collapsing');
        });

      // Broadcast the collapse event so child items can also be collapsed.
      this.$scope.$broadcast('msNavigation::collapse');
    });
  }

  expand() {
    // Grab the element that we are going to expand.
    const expandEl = this.$element.children('ul');

    // Move the element out of the dom flow and
    // make it block so we can get its height.
    expandEl.css({
      position: 'absolute',
      visibility: 'hidden',
      display: 'block',
      height: 'auto',
    });

    // Grab the height.
    const height = expandEl[0].offsetHeight;

    // Reset the style modifications.
    expandEl.css({
      position: '',
      visibility: 'visible',
      display: 'block',
      height: '',
     });

    this.$scope.$evalAsync(() => {
      // Set collapsed status.
      this.collapsed = false;

      // Add expanding class to the node.
      this.$element.addClass('expanding');

      // Animate the height.
      this.$animate
        .animate(
          expandEl,
          { display: 'block', height: '0px' },
          { height: height + 'px' },
          this.animateHeightClass,
        )
        .then(() => {
          // Clear the inline styles after animation done.
          expandEl.css({ height: '' });

          // Clear expanding class from the node.
          this.$element.removeClass('expanding');
        });

      // If item expanded, broadcast the collapse event from rootScope so that the other expanded items
      // can be collapsed. This is necessary for keeping only one parent expanded at any time.
      this.$rootScope.$broadcast('msNavigation::collapse', this.node._path);
    });
  }

  getClass() {
    return this.node.class;
  }
}

const msNavigationNodeDirective = () => {
  return {
    restrict: 'A',
    bindToController: {
      node: '=msNavigationNode',
    },
    controller: MsNavigationNodeController,
    controllerAs: 'vm',
    compile: (tElement) => {
      tElement.addClass('ms-navigation-node');

      return (scope, iElement, iAttrs, msNavigationNodeCtrl) => {
        // Add custom classes
        iElement.addClass(msNavigationNodeCtrl.getClass());

        // Add group class if it's a group
        if (msNavigationNodeCtrl.group) {
          iElement.addClass('group');
        }
      };
    },
  };
}

const msNavigationItemDirective = () => {
  return {
    restrict: 'A',
    require: '^msNavigationNode',
    compile: (tElement) => {
      tElement.addClass('ms-navigation-item');

      return (scope, iElement, iAttrs, msNavigationNodeCtrl) => {
        // If the item is collapsable...
        if (msNavigationNodeCtrl.collapsable) {
          iElement.on('click', msNavigationNodeCtrl.toggleCollapsed);
        }

        // Cleanup
        scope.$on('$destroy', () => {
          iElement.off('click');
        });
      };
    },
  };
}

const msNavigationHorizontalDirective = (msNavigationService) => {
  return {
    restrict: 'E',
    scope: {
      root: '@',
    },
    controller: MsNavigationController,
    controllerAs: 'vm',
    templateUrl: 'src/app/core/directives/ms-navigation/templates/horizontal.html',
    transclude: true,
    compile: (tElement) => {
      tElement.addClass('ms-navigation-horizontal');

      return (scope) => {
        // Store the navigation in the service for public access
        msNavigationService.setNavigationScope(scope);
      };
    },
  };
}

class MsNavigationHorizontalNodeController {
  $scope: any;
  $element: any;
  $rootScope: any;
  $state: any;
  $transitions: any;
  msNavigationService: any;

  group: boolean;
  node: any;
  hasChildren: boolean;
  isActive: boolean;

  constructor($scope, $element, $rootScope, $state, $transitions, msNavigationService) {
    this.$scope = $scope;
    this.$element = $element;
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$transitions = $transitions;
    this.msNavigationService = msNavigationService;

    this.group = false;
    this.node = $scope.node;
    this.hasChildren = false;
    this.isActive = false;

    this.init();
  }

  init() {
    // Has children?
    this.hasChildren = !!this.node.children;

    // Is group?
    this.group = !!(angular.isDefined(this.node.group) && this.node.group === true);

    // Mark all parents as active if we have a matching state
    // or the current state is a child of the node's state.
    if (this.node.state === this.$state.current.name || this.$state.includes(this.node.state)) {
      // If state params are defined, make sure they are
      // equal, otherwise do not set the active item.
      if (
        angular.isDefined(this.node.stateParams) &&
        angular.isDefined(this.$state.params) &&
        !angular.equals(this.node.stateParams, this.$state.params)
      ) {
        return;
      }

      this.$scope.$emit('msNavigation::stateMatched');

      // Also store the current active menu item.
      this.msNavigationService.setActiveItem(this.node, this.$scope);
    }

    this.$scope.$on('msNavigation::stateMatched', () => {
      if (this.hasChildren) {
        this.$scope.$evalAsync(() => {
          this.isActive = true;
        });
      }
    });

    // Listen for clearActive event.
    this.$scope.$on('msNavigation::clearActive', () => {
      if (!this.hasChildren) {
        return;
      }

      let activePathParts = [];
      const activeItem = this.msNavigationService.getActiveItem();
      if (activeItem) {
        activePathParts = activeItem.node._path.split('.');
      }

      if (activePathParts.indexOf(this.node._id) > -1) {
        this.$scope.$evalAsync(() => {
          this.isActive = true;
        });
      } else {
        this.$scope.$evalAsync(() => {
          this.isActive = false;
        });
      }
    });

    // Listen for transitions 'onSuccess' event.
    this.$transitions.onSuccess({}, () => {
      if (this.node.state === this.$state.current.name || this.$state.includes(this.node.state)) {
        // If state params are defined, make sure they are
        // equal, otherwise do not set the active item.
        if (
          angular.isDefined(this.node.stateParams) &&
          angular.isDefined(this.$state.params) &&
          !angular.equals(this.node.stateParams, this.$state.params)
        ) {
          return;
        }

        // Update active item on state change.
        this.msNavigationService.setActiveItem(this.node, this.$scope);

        // Clear all active states except the one we're using.
        this.$rootScope.$broadcast('msNavigation::clearActive');
      }
    });
  }

  getClass() {
    return this.node.class;
  }
}

const msNavigationHorizontalNodeDirective = () => {
  return {
    restrict: 'A',
    bindToController: {
      node: '=msNavigationHorizontalNode',
    },
    controller: MsNavigationHorizontalNodeController,
    controllerAs: 'vm',
    compile: (tElement) => {
      tElement.addClass('ms-navigation-horizontal-node');

      return (scope, iElement, iAttrs, msNavigationHorizontalNodeCtrl) => {
        // Add custom classes
        iElement.addClass(msNavigationHorizontalNodeCtrl.getClass());

        // Add group class if it's a group
        if (msNavigationHorizontalNodeCtrl.group) {
          iElement.addClass('group');
        }
      };
    },
  };
}

const msNavigationHorizontalItemDirective = ($mdMedia) => {
  return {
    restrict: 'A',
    require: '^msNavigationHorizontalNode',
    compile: (tElement) => {
      tElement.addClass('ms-navigation-horizontal-item');

      return (scope, iElement, iAttrs, msNavigationHorizontalNodeCtrl) => {
        iElement.on('click', () => {
          if (!msNavigationHorizontalNodeCtrl.hasChildren || $mdMedia('gt-md')) {
            return;
          }

          iElement.toggleClass('expanded');
        });

        // Cleanup
        scope.$on('$destroy', () => {
          iElement.off('click');
        });
      };
    },
  };
}

angular
  .module('app.core')
  .provider('msNavigationService', MsNavigationServiceProvider)
  .controller('MsNavigationController', MsNavigationController)
  // Vertical
  .controller('MsNavigationNodeController', MsNavigationNodeController)
  .directive('msNavigation', msNavigationDirective)
  .directive('msNavigationNode', msNavigationNodeDirective)
  .directive('msNavigationItem', msNavigationItemDirective)
  // Horizontal
  .controller('MsNavigationHorizontalNodeController', MsNavigationHorizontalNodeController)
  .directive('msNavigationHorizontal', msNavigationHorizontalDirective)
  .directive('msNavigationHorizontalNode', msNavigationHorizontalNodeDirective)
  .directive('msNavigationHorizontalItem', msNavigationHorizontalItemDirective);
import * as angular from 'angular';

angular.module('app.core').directive('msSplashScreen', ($animate) => {
  return {
    restrict: 'E',
    link: (scope, iElement) => {
      const splashScreenHideEvent = scope.$on('msSplashScreen::hide', () => {
        // Hide the splash screen.
        iElement.addClass('ng-hide');
      });

      const splashScreenShowEvent = scope.$on('msSplashScreen::show', () => {
        // Show the splash screen.
        iElement.removeClass('ng-hide');
      });

      const splashScreenRemoveEvent = scope.$on('msSplashScreen::remove', () => {
        $animate.leave(iElement).then(() => {
          // De-register scope eventa.
          splashScreenHideEvent();
          splashScreenShowEvent();
          splashScreenRemoveEvent();

          // Null-ify everything else.
          scope = iElement = null;
        });
      });
    },
  };
});

import * as angular from 'angular';

angular.module('app.core').filter('selectedCount', () => {
  return <T extends { selected: boolean }>(items: T[]): number => {
    if (!items) {
      return 0;
    }

    return items.filter((a) => a.selected).length;
  };
});

import * as angular from 'angular';
import { TableControl } from '@common/helpers/TableControl';

angular.module('app.core').filter('accountFilter', ($filter: angular.IFilterService) => {
  return (items: fuse.accountDetail[], tableControl: TableControl) => {
    if (!items) return [];
    items = items.filter((account) => {
      const incl: boolean = angular.isDefined(tableControl.searchKeyword)
        ? (account.companyName && account.companyName.toLowerCase().indexOf(tableControl.searchKeyword.toLowerCase()) > -1) ||
          (account.businessName && account.businessName.toLowerCase().indexOf(tableControl.searchKeyword.toLowerCase()) > -1)
        : true;
      return incl;
    });
    tableControl.total = items.length;
    items = $filter('orderBy')(items, tableControl.orderByField, tableControl.reverseSort);
    items = items.slice(tableControl.calculateStartFrom());
    return $filter('limitTo')(items, tableControl.pageSize);
  };
});

import * as angular from 'angular';

class MsThemeOptionsController {
  themes: any;
  layoutModes: { label: string; value: string }[];
  layoutStyles: { label: string; value: string; figure: string }[];
  layoutMode: string;
  layoutStyle: string;
  $cookies: any;
  fuseTheming: any;

  constructor($cookies, fuseTheming) {
    this.$cookies = $cookies;
    this.fuseTheming = fuseTheming;

    // Data
    this.themes = fuseTheming.themes;

    this.layoutModes = [
      { label: 'Boxed', value: 'boxed' },
      { label: 'Wide', value: 'wide' },
    ];

    this.layoutStyles = [
      {
        label: 'Vertical Navigation',
        value: 'verticalNavigation',
        figure: 'assets/images/theme-options/vertical-nav.jpg',
      },
      {
        label: 'Vertical Navigation with Fullwidth Toolbar',
        value: 'verticalNavigationFullwidthToolbar',
        figure: 'assets/images/theme-options/vertical-nav-with-full-toolbar.jpg',
      },
      {
        label: 'Vertical Navigation with Fullwidth Toolbar 2',
        value: 'verticalNavigationFullwidthToolbar2',
        figure: 'assets/images/theme-options/vertical-nav-with-full-toolbar-2.jpg',
      },
      {
        label: 'Horizontal Navigation',
        value: 'horizontalNavigation',
        figure: 'assets/images/theme-options/horizontal-nav.jpg',
      },
      {
        label: 'Content with Toolbar',
        value: 'contentWithToolbar',
        figure: 'assets/images/theme-options/content-with-toolbar.jpg',
      },
      {
        label: 'Content Only',
        value: 'contentOnly',
        figure: 'assets/images/theme-options/content-only.jpg',
      },
    ];

    this.layoutMode = 'wide';
    this.layoutStyle = $cookies.get('layoutStyle') || 'verticalNavigation';
  }

  setActiveTheme(themeName: string) {
    this.fuseTheming.setActiveTheme(themeName);
  }

  getActiveTheme() {
    return this.fuseTheming.themes.active;
  }

  updateLayoutMode() {
    const bodyEl = angular.element(document.querySelector('body'));

    // Update class on body element
    bodyEl.toggleClass('boxed', this.layoutMode === 'boxed');
  }

  updateLayoutStyle() {
    // Update the cookie
    this.$cookies.put('layoutStyle', this.layoutStyle);

    // Reload the page to apply the changes
    location.reload();
  }
}

const msThemeOptions = ($mdSidenav) => {
  return {
    restrict: 'E',
    scope: {},
    controller: MsThemeOptionsController,
    controllerAs: 'vm',
    templateUrl: 'src/app/core/directives/ms-theme-options/ms-theme-options.html',
    compile: (tElement) => {
      tElement.addClass('ms-theme-options');

      return (scope) => {
        // Expose the toggle function
        scope.toggleOptionsSidenav = () => {
          // Toggle the fuse theme options panel
          $mdSidenav('fuse-theme-options').toggle();
        };
      };
    },
  };
}

angular
  .module('app.core')
  .controller('MsThemeOptionsController', MsThemeOptionsController)
  .directive('msThemeOptions', msThemeOptions);

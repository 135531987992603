import * as angular from 'angular';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';

class MsStepperController {
  accountCustomStyling: any;
  currentStepNumber: number;
  steps = [];

  private $timeout: angular.ITimeoutService;
  private _mainForm: any;
  private _orientation = 'horizontal';

  constructor($timeout, PermissionService) {
    this.accountCustomStyling = PermissionService.accountCustomStyling;
    this.currentStepNumber = 1;
    this.steps = [];

    this.$timeout = $timeout;
    this._mainForm = undefined;
    this._orientation = 'horizontal';

    this.filterHiddenStep = this.filterHiddenStep.bind(this);
    this.gotoNextStep = this.gotoNextStep.bind(this);
    this.gotoPreviousStep = this.gotoPreviousStep.bind(this);
    this.gotoStep = this.gotoStep.bind(this);
    this.isFirstStep = this.isFirstStep.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
    this.isLastStep = this.isLastStep.bind(this);
    this.isStepCurrent = this.isStepCurrent.bind(this);
    this.isStepDisabled = this.isStepDisabled.bind(this);
    this.isStepHidden = this.isStepHidden.bind(this);
    this.isStepOptional = this.isStepOptional.bind(this);
    this.isStepValid = this.isStepValid.bind(this);
    this.resetForm = this.resetForm.bind(this);
  }

  setOrientation(orientation) {
    this._orientation = orientation || 'horizontal';
  }

  registerMainForm(form) {
    this._mainForm = form;
  }

  registerStep(element, scope, form) {
    const step = {
      element: element,
      form: form,
      scope: scope,
      stepNumber: scope.step || (this.steps.length + 1),
      stepTitle: scope.stepTitle,
    };

    // Push the step into steps array.
    this.steps.push(step);

    // Sort steps by stepNumber.
    this.steps = ArrayUtils.sortByNumber(this.steps, (x) => x.stepNumber);

    return step;
  }

  setupSteps() {
    this.setCurrentStep(this.currentStepNumber);
  }

  resetForm() {
    this.$timeout(() => {
      this.steps.forEach(step => {
        step.form.$setPristine();
        step.form.$setUntouched();
      });

      // Reset the main form.
      this._mainForm.$setPristine();
      this._mainForm.$setUntouched();

      // Go to first step.
      this.gotoFirstStep();
    });
  }

  gotoStep(stepNumber) {
    if (this.isStepHidden(stepNumber)) {
      return;
    }

    this.setCurrentStep(stepNumber);
  }

  gotoPreviousStep() {
    let stepNumber = this.currentStepNumber - 1;

    // Test the previous steps and make sure we
    // will land to the one that is not hidden
    for (let s = stepNumber; s >= 1; s--) {
      if (!this.isStepHidden(s)) {
        stepNumber = s;
        break;
      }
    }

    this.setCurrentStep(stepNumber);
  }

  gotoNextStep() {
    let stepNumber = this.currentStepNumber + 1;

    // Test the following steps and make sure we
    // will land to the one that is not hidden
    for (let s = stepNumber; s <= this.steps.length; s++) {
      if (!this.isStepHidden(s)) {
        stepNumber = s;
        break;
      }
    }

    this.setCurrentStep(stepNumber);
  }

  isFirstStep() {
    return this.currentStepNumber === 1;
  }

  isLastStep() {
    return this.currentStepNumber === this.steps.length;
  }

  isStepCurrent(stepNumber: number) {
    if (!this.isStepNumberValid(stepNumber)) {
      return null;
    }

    return this.currentStepNumber === stepNumber;
  }

  isStepDisabled(stepNumber: number) {
    if (!this.isStepNumberValid(stepNumber)) {
      return null;
    }

    for (let i = 1; i < stepNumber; i++) {
      if (!this.isStepValid(i)) {
        return true;
      }
    }

    return false;
  }

  isStepOptional(stepNumber: number) {
    if (!this.isStepNumberValid(stepNumber)) {
      return null;
    }

    return this.steps[stepNumber - 1].scope.optionalStep;
  }

  isStepHidden(stepNumber: number) {
    if (!this.isStepNumberValid(stepNumber)) {
      return null;
    }

    return !!this.steps[stepNumber - 1].scope.hideStep;
  }

  isStepValid(stepNumber: number) {
    if (!this.isStepNumberValid(stepNumber)) {
      return null;
    }

    // If the step is optional, always return true.
    if (this.isStepOptional(stepNumber)) {
      return true;
    }

    return this.steps[stepNumber - 1].form.$valid;
  }

  isFormValid() {
    return this._mainForm.$valid;
  }

  filterHiddenStep(step) {
    return !this.isStepHidden(step.stepNumber);
  }

  private isStepNumberValid(stepNumber: number) {
    return !(angular.isUndefined(stepNumber) || stepNumber < 1 || stepNumber > this.steps.length);
  }

  private setCurrentStep(stepNumber) {
    if (!this.isStepNumberValid(stepNumber)) {
      return;
    }

    // Update the current step number
    this.currentStepNumber = stepNumber;

    if (this._orientation === 'horizontal') {
      // Hide all steps.
      this.steps.forEach(step => step.element.hide());

      // Show the current step.
      this.steps[this.currentStepNumber - 1].element.show();
    } else if (this._orientation === 'vertical') {
      // Hide all step content.
      this.steps.forEach(step => step.element.find('.ms-stepper-step-content').hide());

      // Show the current step content.
      this.steps[this.currentStepNumber - 1].element.find('.ms-stepper-step-content').show();
    }
  }

  private gotoFirstStep() {
    this.setCurrentStep(1);
  }

  private gotoLastStep() {
    this.setCurrentStep(this.steps.length);
  }
}

const msHorizontalStepperDirective = () => {
  return {
    restrict: 'A',
    scope: {},
    require: ['form', 'msHorizontalStepper'],
    priority: 1001,
    controller: 'MsStepperController as MsStepper',
    bindToController: {
      model: '=ngModel',
      currentStepNumber: '='
    },
    transclude: true,
    templateUrl: 'src/app/core/directives/ms-stepper/templates/horizontal/horizontal.html',
    compile: (tElement) => {
      tElement.addClass('ms-stepper');

      return function postLink(scope, iElement, iAttrs, ctrls) {
        const FormCtrl = ctrls[0];
        const MsStepperCtrl = ctrls[1];

        // Register the main form and setup
        // the steps for the first time
        MsStepperCtrl.setOrientation('horizontal');
        MsStepperCtrl.registerMainForm(FormCtrl);
        MsStepperCtrl.setupSteps();
      };
    }
  };
}

const msHorizontalStepperStepDirective = () => {
  return {
    restrict: 'E',
    require: ['form', '^msHorizontalStepper'],
    priority: 1000,
    scope: {
      step: '=?',
      stepTitle: '=?',
      optionalStep: '=?',
      hideStep: '=?'
    },
    compile: (tElement) => {
      tElement.addClass('ms-stepper-step');

      return (scope, iElement, iAttrs, ctrls) => {
        var FormCtrl = ctrls[0],
          MsStepperCtrl = ctrls[1];

        // Is it an optional step?
        scope.optionalStep = angular.isDefined(iAttrs.optionalStep);

        // Register the step
        MsStepperCtrl.registerStep(iElement, scope, FormCtrl);

        // Hide the step by default
        iElement.hide();
      };
    }
  };
}

const msVerticalStepperDirective = ($timeout) => {
  return {
    restrict: 'A',
    scope: {},
    require: ['form', 'msVerticalStepper'],
    priority: 1001,
    controller: 'MsStepperController as MsStepper',
    bindToController: {
      model: '=ngModel'
    },
    transclude: true,
    templateUrl: 'src/app/core/directives/ms-stepper/templates/vertical/vertical.html',
    compile: (tElement) => {
      tElement.addClass('ms-stepper');

      return (scope, iElement, iAttrs, ctrls) => {
        var FormCtrl = ctrls[0],
          MsStepperCtrl = ctrls[1];

        // Register the main form and setup
        // the steps for the first time

        // Timeout is required in vertical stepper
        // as we are using transclusion in steps.
        // We have to wait for them to be transcluded
        // and registered to the controller
        $timeout(() => {
          MsStepperCtrl.setOrientation('vertical');
          MsStepperCtrl.registerMainForm(FormCtrl);
          MsStepperCtrl.setupSteps();
        });
      };
    }
  };
}

const msVerticalStepperStepDirective = () => {
  return {
    restrict: 'E',
    require: ['form', '^msVerticalStepper'],
    priority: 1000,
    scope: {
      step: '=?',
      stepTitle: '=?',
      optionalStep: '=?',
      hideStep: '=?'
    },
    transclude: true,
    templateUrl: 'src/app/core/directives/ms-stepper/templates/vertical/step/vertical-step.html',
    compile: (tElement) => {
      tElement.addClass('ms-stepper-step');

      return (scope, iElement, iAttrs, ctrls) => {
        var FormCtrl = ctrls[0],
          MsStepperCtrl = ctrls[1];

        // Is it an optional step?
        scope.optionalStep = angular.isDefined(iAttrs.optionalStep);

        // Register the step
        scope.stepInfo = MsStepperCtrl.registerStep(iElement, scope, FormCtrl);

        // Expose the controller to the scope
        scope.MsStepper = MsStepperCtrl;

        // Hide the step content by default
        iElement.find('.ms-stepper-step-content').hide();
      };
    }
  };
}

angular
  .module('app.core')
  .controller('MsStepperController', MsStepperController)
  .directive('msHorizontalStepper', msHorizontalStepperDirective)
  .directive('msHorizontalStepperStep', msHorizontalStepperStepDirective)
  .directive('msVerticalStepper', msVerticalStepperDirective)
  .directive('msVerticalStepperStep', msVerticalStepperStepDirective);
import * as angular from 'angular';
import { DateUtils } from '@indicina/swan-shared/utils/DateUtils';
import { LanguageService } from '@services/language.service';

angular.module('app.core').filter('effectivePeriod', (LanguageService: LanguageService) => {
  return <T extends { effectiveFromDate: Date; effectiveToDate: Date }>(item: T): string => {
    if (!item) {
      return null;
    }

    const from = item.effectiveFromDate ? DateUtils.Locale.asDateMedium(item.effectiveFromDate) : '-';

    if (item.effectiveToDate != null) {
      const to = DateUtils.Locale.asDateMedium(item.effectiveToDate);

      return `${LanguageService.instant('COMMON.FROM')} ${from} ${LanguageService.instant('COMMON.TO')} ${to}`;
    }

    return `${LanguageService.instant('COMMON.FROM')} ${from} ${LanguageService.instant('COMMON.TO_PRESENT')}`;
  };
});

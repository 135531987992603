import * as angular from 'angular';
import { unitSizes } from '@common/enums';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';

angular.module('app.core').filter('convertUnits', (
  $filter: angular.IFilterService,
  UnitOfMeasureService: UnitOfMeasureService,
) => {
  return (unitValue: string, unitType: string, size = unitSizes.normal, direction = '<', decimalPlaces: number = null) => {
    const value = parseFloat(unitValue);

    if (isNaN(value)) {
      return null;
    }

    if (!unitType) {
      console.error('convertUnits: unitType must be specified');

      return null;
    }

    const uomUnit = UnitOfMeasureService.getUnits(unitType, size);

    if (!direction || direction == '<') {
      if (!decimalPlaces) {
        decimalPlaces = uomUnit.decimalPlaces;
      }

      const convertedValue = uomUnit.fromBaseRounded(value, decimalPlaces);

      if (decimalPlaces >= 0) {
        return $filter<any>('number')(convertedValue, decimalPlaces);
      }

      // Negative decimal places (-n) is a flag meaning "round to MAXIMUM of n decimal places, otherwise trim zeroes"
      return convertedValue;
    } else if (direction == '>') {
      const convertedValue = uomUnit.toBase(value);

      return convertedValue;
    } else {
      alert('convertUnits: the direction value specified is invalid');

      return null;
    }
  };
});

import * as angular from 'angular';
import { uomUnit } from '@services/unit-of-measure.service';
import { UnitTypes } from '@common/enums';

angular.module('app.core').filter('convertTo', ($filter: angular.IFilterService) => {
  return (value, unit: uomUnit, showUnit: boolean, overrideDecimals = null) => {
    if (isNaN(value)) return;
    if (unit == null) {
      console.log('Error: unit cannot be null');
      return 0;
    }

    if (overrideDecimals == null) {
      overrideDecimals = unit.decimalPlaces;
    } else {
      const a = 0;
    }

    const convertVal = parseFloat(value);
    let converted = unit.fromBase(convertVal);
    converted = $filter<any>('number')(converted, overrideDecimals);

    if (showUnit) {
      let spacer = unit.context == UnitTypes.Temperature ? '' : ' ';
      spacer = showUnit === true ? spacer : showUnit;
      converted = `${converted}${spacer}${unit.name}`;
    }
    return converted;
  };
});


import * as angular from 'angular';

const appCoreConfig = ($logProvider: angular.ILogProvider, toastrConfig: ToastrOptions) => {
  // Enable debug logging
  $logProvider.debugEnabled(true);

  angular.extend(toastrConfig, {
    positionClass: 'toast-bottom-right',
    target: '#toast-target, #toast-target-fullscreen',
  });
}

const themeConfig = ($mdThemingProvider, fusePalettes, fuseThemes) => {
  // Inject Cookies Service
  let $cookies;
  angular.injector(['ngCookies']).invoke([
    '$cookies',
    (_$cookies) => {
      $cookies = _$cookies;
    },
  ]);

  // Check if custom theme exist in cookies
  const customTheme = $cookies.getObject('customTheme');
  if (customTheme) {
    fuseThemes['custom'] = customTheme;
  }

  $mdThemingProvider.alwaysWatchTheme(true);

  // Define custom palettes
  angular.forEach(fusePalettes, (palette) => {
    $mdThemingProvider.definePalette(palette.name, palette.options);
  });

  // Register custom themes
  angular.forEach(fuseThemes, (theme, themeName) => {
    $mdThemingProvider
      .theme(themeName)
      .primaryPalette(theme.primary.name, theme.primary.hues)
      .accentPalette(theme.accent.name, theme.accent.hues)
      .warnPalette(theme.warn.name, theme.warn.hues)
      .backgroundPalette(theme.background.name, theme.background.hues);
  });
}

angular.module('app.core')
  .config(['$logProvider', 'toastrConfig', appCoreConfig])
  .config(['$mdThemingProvider', 'fusePalettes', 'fuseThemes', themeConfig]);